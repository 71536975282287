<template>
  <SectionY class="relative" :padding="data.padding?.value">
    <CloudTopIcon
      v-if="data.style?.value === 'bubble'"
      class="absolute top-0 w-full translate-y-[calc(-100%+1px)] text-white"
    />
    <ContainerX>
      <!-- TITLE -->

      <h2
        v-if="data.heading"
        class="default-component-title mx-auto mb-container-sm sm:mb-container-xl xl:mb-container-max"
        :class="{ 'pt-11': data.style?.value === 'bubble' }"
        v-html="$replaceHtmlTags(data.heading)"
      />

      <!-- DESKTOP -->
      <ul
        class="hidden grid-cols-1 gap-14 xl:gap-20 2xl:grid"
        :class="{
          '3xl:grid-cols-4': data.cards.length > 3,
          'xl:grid-cols-3': data.cards.length > 2,
          'sm:grid-cols-2': data.cards.length > 1,
        }"
      >
        <li v-for="(card, index) in data.cards" :key="index">
          <InViewAnimate :delay="200 * index || 0">
            <ColumnCard
              :card="card"
              :index="index"
              :amount-cards="data.cards.length"
              :has-heading="!!data.heading"
              :has-dotted-connection-lines="
                data.dotted_connection_lines?.value === 'show'
              "
            />
          </InViewAnimate>
        </li>
      </ul>
    </ContainerX>
    <!-- MOBILE -->
    <div class="2xl:hidden">
      <InViewAnimate>
        <Swiper
          :slides-per-view="1"
          :breakpoints="breakpoints"
          class="relative"
          @swiper="onSwiper"
          @reach-beginning="
            $refs.swiperButtons.reachedBeginningOrEnd('beginning')
          "
          @reach-end="$refs.swiperButtons.reachedBeginningOrEnd('end')"
          @from-edge="$refs.swiperButtons.fromEdge()"
        >
          <SwiperSlide v-for="(card, index) in data.cards" :key="index">
            <ColumnCard
              :card="card"
              :index="index"
              :amount-cards="data.cards.length"
              :has-heading="!!data.heading"
              class="ml-container shrink-0"
              is-slider
              :has-dotted-connection-lines="
                data.dotted_connection_lines?.value === 'show'
              "
            />
          </SwiperSlide>
          <div class="inset-x-0 top-1/2 z-10 mt-9">
            <SwiperButtons
              ref="swiperButtons"
              class="mx-auto max-w-[6.75rem]"
              @prev="swiper.slidePrev()"
              @next="swiper.slideNext()"
            />
          </div>
        </Swiper>
      </InViewAnimate>
    </div>
  </SectionY>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const breakpoints = {
  850: {
    // lg
    slidesPerView: 2,
  },
}

// SWIPER
let swiper = {}

const onSwiper = (swiperElement) => {
  swiper = swiperElement
}
</script>
